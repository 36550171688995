import React from "react";
import {
  HobbiesContainer,
  HobbiesWrapper,
  HobbyCard,
  HobbyImage,
  HobbyTitle,
  HobbyDescription,
  ImageHeading,
  LearnMoreButton, // Importing the Learn More button styling
} from "./HobbiesElements";
import b1 from "../../Pics/Leetcode.png";
import b2 from "../../Pics/Community.png";
import b11 from "../../Pics/Cycling.png";
import b21 from "../../Pics/Basketball.png";
import b22 from "../../Pics/Food.jpg";
import hobbiesHeading from "../../Pics/Hobbies.png"; // Image for the heading

const hobbyPosts = [
  {
    id: 1,
    title: "Leetcode",
    description: "Exploring algorithms, writing efficient code, and cherishing the learning process.",
    image: b1,
  },
  {
    id: 2,
    title: "Community",
    description: "I am working on contributing my solved DSA problems to gitHub community.",
    image: b2,
  },
  {
    id: 3,
    title: "Basketball",
    description: "On the court, I feel alive, with every movement being a testament to my dedication and love for the game.",
    image: b21,
  },
  {
    id: 4,
    title: "Cycling",
    description: "With every turn of the pedals, I discover new adventures and a profound sense of freedom, escaping reality.",
    image: b11,
  },
  {
    id: 5,
    title: "Cooking",
    description: "Good food makes my heart happy and if that food is made by me then it's extra special (:",
    image: b22,
  },
];

function Hobbies() {
  return (
    <HobbiesContainer id="hobbies">
      {/* Heading Image */}
      <HobbiesWrapper>
        <ImageHeading src={hobbiesHeading} alt="Hobbies" />
        {/* First Row: 2 Cards */}
        {hobbyPosts.slice(0, 2).map((post, index) => (
          <HobbyCard key={index}>
            <HobbyImage src={post.image} alt={post.title} />
            <HobbyTitle>{post.title}</HobbyTitle>
            <HobbyDescription>{post.description}</HobbyDescription>
            <LearnMoreButton
              href={index === 0 ? "https://leetcode.com/u/LebroGems/" : "https://github.com/Sanjeev2601/Leetcode_Streak"} // Links to Google and YouTube
              target="_blank"
            >
              Learn More
            </LearnMoreButton>
          </HobbyCard>
        ))}
      </HobbiesWrapper>
      {/* Second Row: 3 Cards */}
      <HobbiesWrapper>
        {hobbyPosts.slice(2).map((post, index) => (
          <HobbyCard key={index}>
            <HobbyImage src={post.image} alt={post.title} />
            <HobbyTitle>{post.title}</HobbyTitle>
            <HobbyDescription>{post.description}</HobbyDescription>
          </HobbyCard>
        ))}
      </HobbiesWrapper>
    </HobbiesContainer>
  );
}

export default Hobbies;
