import React, { useState } from "react";
import {
  BlogContainer,
  CloudImage,
  CloudWrapper,
  FooterWrapper,
  FooterImage,
  FooterText,
  Sparkle,
  SparkleContainer,
} from "./BlogElements";

// Importing the cloud images
import cloud1 from "../../Pics/cloud1.png";
import cloud2 from "../../Pics/cloud2.png";
import cloud3 from "../../Pics/cloud3.png";
import cloud4 from "../../Pics/cloud4.png";
import cloud5 from "../../Pics/cloud5.png";
import cloud6 from "../../Pics/cloud6.png";

// Importing footer image
import footerImage from "../../Pics/BlogMe.png";

// Importing individual pop-up components
import Cloud1Popup from "./Cloud1Popup";
import Cloud2Popup from "./Cloud2Popup";
import Cloud3Popup from "./Cloud3Popup";
import Cloud4Popup from "./Cloud4Popup";
import Cloud5Popup from "./Cloud5Popup";
import Cloud6Popup from "./Cloud6Popup";

const createSparkles = (num) => {
  const sparkles = [];
  for (let i = 0; i < num; i++) {
    const top = Math.random() * 60; 
    const left = Math.random() * 100;
    sparkles.push(<Sparkle key={i} style={{ top: `${top}%`, left: `${left}%` }} />);
  }
  return sparkles;
};
function Blog() {
  const [popupOpen, setPopupOpen] = useState(null); // To track which cloud's pop-up is open

  // Function to open pop-up for each cloud
  const handleCloudClick = (cloudNumber) => {
    setPopupOpen(cloudNumber); // Open the specific pop-up for the clicked cloud
  };

  // Function to close any open pop-up
  const closePopup = () => {
    setPopupOpen(null);
  };

  return (
    <BlogContainer id="blog">
      <SparkleContainer>{createSparkles(60)}</SparkleContainer>
      {/* Cloud Wrapper */}
      <CloudWrapper>
        <CloudImage src={cloud1} alt="Cloud 1" className="cloud1" onClick={() => handleCloudClick(1)} />
        <CloudImage src={cloud2} alt="Cloud 2" className="cloud2" onClick={() => handleCloudClick(2)} />
        <CloudImage src={cloud3} alt="Cloud 3" className="cloud3" onClick={() => handleCloudClick(3)} />
        <CloudImage src={cloud4} alt="Cloud 4" className="cloud4" onClick={() => handleCloudClick(4)} />
        <CloudImage src={cloud5} alt="Cloud 5" className="cloud5" onClick={() => handleCloudClick(5)} />
        <CloudImage src={cloud6} alt="Cloud 6" className="cloud6" onClick={() => handleCloudClick(6)} />
      </CloudWrapper>

      {/* Conditionally render each cloud's pop-up without altering other elements */}
      {popupOpen === 1 && <Cloud1Popup closePopup={closePopup} />}
      {popupOpen === 2 && <Cloud2Popup closePopup={closePopup} />}
      {popupOpen === 3 && <Cloud3Popup closePopup={closePopup} />}
      {popupOpen === 4 && <Cloud4Popup closePopup={closePopup} />}
      {popupOpen === 5 && <Cloud5Popup closePopup={closePopup} />}
      {popupOpen === 6 && <Cloud6Popup closePopup={closePopup} />}

      {/* Footer with image and text */}
      <FooterWrapper>
        <FooterImage src={footerImage} alt="Footer" />
        <FooterText>Welcome to my blog</FooterText>
      </FooterWrapper>
    </BlogContainer>
  );
}

export default Blog;
