import styled from "@emotion/styled";

export const Line = styled.hr`
  width: 100%;
  border: none;
  border-top: 2px solid #ccc;
  margin-top: -14px;
`;

export const ContactWrapper = styled.div`
  margin-top: 5rem;
`;

export const Image = styled.img`
  max-width: 120px;
  margin: 0 auto;
  margin-bottom: 1rem;
`;

export const AboutContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;

@media screen and (max-width: 768px) {
  flex-direction: column;
  align-items: center;
}

`;

export const ImageContainer = styled.div`
  margin-top: -15px;
  flex: 2;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 700px; /* Set the width of the image to fill the container */
    height: 135px; /* Set the height of the image to fill the container */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    background-color: transparent;
    border-radius: 10%; /* Round the image itself */
  }
`;

export const DescriptionContainer = styled.div`
  flex: 7;
`;

export const Technologies = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -2rem;
`;

export const Tech = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  min-width: 100px;
  margin-bottom: 2rem;
`;

export const TechImg = styled.img`
  height: 40px;
  width: 40px;
  padding-bottom: 5px;
`;

export const TechName = styled.div`
  font-size: 14px;
`;
