import styled from "styled-components";

// Container for the hobbies page
export const HobbiesContainer = styled.div`
  padding: 4rem 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #151418;
  width: 100%;
  overflow: hidden;
`;

// Image for the heading
export const ImageHeading = styled.img`
  width: 450px;
  margin-bottom: 2rem;
  margin-left: -50px;
  margin-right: -100px;
  margin-top: -64px;
`;

// Wrapper for the hobby cards
export const HobbiesWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
  width: 1300px; /* Adjust width as needed */
  margin-bottom: 50px;
`;

// Individual hobby card styling
export const HobbyCard = styled.div`
  width: 300px;
  height: 390px;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }
`;

// Image inside the hobby card
export const HobbyImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

// Title inside the hobby card
export const HobbyTitle = styled.h3`
  font-size: 1.5rem;
  color: #f6f6f6;
  padding: 0.5rem;
  text-align: center;
`;

// Description inside the hobby card
export const HobbyDescription = styled.p`
  font-size: 1rem;
  color: rgb(119, 119, 121);
  padding: 0 1rem;
  text-align: center;
`;

// Button for "Learn More"
export const LearnMoreButton = styled.a`
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #c0392b;
  }
`;
