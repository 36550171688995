import styled, { keyframes } from "styled-components";

// Container for the blog page, with the beach image as the background
export const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #151418;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden; /* Ensure no scrollbars appear */
`;

// Cloud wrapper to position the clouds
export const CloudWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

// Styling for each cloud image
export const CloudImage = styled.img`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;

  &.cloud1 {
    top: 10px;
    left: 10%;
    width: 200px;
  }

  &.cloud2 {
    top: 30px;
    left: 27%;
    width: 240px;
  }

  &.cloud3 {
    top: -30px;
    left: 50%;
    width: 200px;
  }

  &.cloud4 {
    top: -30px;
    right: 10%;
    width: 200px;
  }

  &.cloud5 {
    top: 120px;
    right: 34%;
    width: 240px;
  }

  &.cloud6 {
    top: 130px;
    left: 70%;
    width: 240px;
  }

  &:hover {
    transform: scale(1.1); /* Smooth scaling effect on hover */
    z-index: 2; /* Ensure it appears on top when hovered */
  }
`;

// Pop-up container
export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3; /* Ensures it appears on top of other elements */
`;

// Pop-up content styling
export const PopupContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  z-index: 4;
`;

// Close button for the pop-up
export const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;

// Footer wrapper for image only (no text)
export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
  justify-content: center;
  margin-top: 365px;
`;

// Footer image
export const FooterImage = styled.img`
  width: 500px;
`;

const sparkleAnimation = keyframes`
  0%, 100% { opacity: 0.7; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.3); }
`;

// Sparkle component styling
export const Sparkle = styled.div`
  position: absolute;
  background-color: white;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  opacity: 0.7;
  animation: ${sparkleAnimation} 2s infinite ease-in-out;
  z-index: 0;
`;

// Container for positioning sparkles
export const SparkleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`;

export const FooterText = styled.p`
  font-size: 30px; /* Make it bigger */
  font-weight: bold; /* Make it bold */
  color: white; /* Set text color to white */
  margin-top: 50px;
  margin-left: 60px;
  text-align: center;
`;