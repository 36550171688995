import React, { useEffect } from 'react';
import './Cloud1Popup.css';
import WelcomeImage from '../../Pics/b2.jpeg';
import B1Image from '../../Pics/Amigo14.jpg';
import B2Image from '../../Pics/Amigo15.jpg';
import B3Image from '../../Pics/Amigo10.jpg';
import B4Image from '../../Pics/Amigo11.jpg';
import B5Image from '../../Pics/Amigo13.jpg';
import A1Image from '../../Pics/Amigo1.png';
import A2Image from '../../Pics/Amigo2.png';
import A3Image from '../../Pics/Amigo3.png';
import A4Image from '../../Pics/Amigo4.png';
import A5Image from '../../Pics/Amigo5.png';
import A6Image from '../../Pics/Amigo6.png';
import A7Image from '../../Pics/Amigo7.png';

const Cloud2Popup = ({ closePopup }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-buttons">
          <button className="popup-button red" onClick={closePopup}></button>
          <button className="popup-button yellow" onClick={closePopup}></button>
          <button className="popup-button green" onClick={closePopup}></button>
        </div>
        
        <h2><strong>From Brainstorming to Bowling: Our Unforgettable VTHacks 2024 Journey</strong></h2>
        <p><em>"Sleep is a luxury we couldn't afford—not when inspiration strikes at 3 AM!"</em></p>

        <div className='Welcome-Image'>
            <img src={WelcomeImage} alt="Black Panther: Battle For Wakanda" />
        </div>

        <p>
          Last weekend was nothing short of a rollercoaster ride for our team at VTHacks 2024 in Blacksburg. Four tech enthusiasts—Ananya, Ruba, Ram, and Sanjeev(myself)—came together with one ambitious goal: to turn a wild idea into a working app in just 36 hours.
        </p>

        <h3><strong>The Spark of an Idea: AmiGo</strong></h3>
        <p>
          It all began with a simple question: What if ride-sharing could be more than just getting from point A to point B? What if it could be an opportunity to connect with like-minded individuals from your own community?
        </p>
        <p>
          And just like that, AmiGo was born—a ride-sharing mobile app that matches carpool buddies based on personality and preferences. We envisioned a platform where every journey becomes a chance for a meaningful conversation, not just a silent commute.
        </p>

        <h3><strong>Diving into the Deep End</strong></h3>
        <p>
          Fueled by endless cups of coffee and an eclectic playlist, we plunged into a night of intense brainstorming. Ideas bounced off the walls (sometimes literally), and our whiteboards at the Virginia Tech Department of Computer Science quickly filled up with sketches and flowcharts.
        </p>
        <p>
          Then came the coding marathon—a solid 24 hours of non-stop development. Time blurred as we juggled:
        </p>
        <ul>
          <li><strong>Frontend Design:</strong> Crafting an intuitive UI with Figma.</li>
          <li><strong>Mobile App Development:</strong> Building the app using React Native and Node.js Express.</li>
          <li><strong>Machine Learning & Database Management:</strong> Implementing smart matching algorithms with Microsoft Azure.</li>
          <li><strong>Deployment:</strong> Bringing our app to life using Defang Software Labs.</li>
        </ul>

        <div className="book-carousel-vertical">
          <div className="carousel-track-vertical">
            <img src={A1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A6Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A7Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A6Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A7Image} alt="Black Panther: Battle For Wakanda" />
          </div>
        </div>

        <h3><strong>Trials, Tribulations, and Triumphs</strong></h3>
        <p>
          Of course, no hackathon is complete without its fair share of hurdles. We faced bugs that seemed to defy logic, debated over design choices until dawn, and battled the inevitable fatigue that comes with pulling an all-nighter.
        </p>
        <p>
          But every challenge only strengthened our resolve. Whether it was a stubborn piece of code or a last-minute feature addition, we tackled it head-on—often with a good dose of humor and a fresh pot of coffee.
        </p>

        <h3><strong>The Moment of Truth</strong></h3>
        <p>
          As the deadline loomed, we put the finishing touches on AmiGo and submitted our project with minutes to spare. Exhausted but exhilarated, we presented our app across multiple categories, hoping our passion would shine through.
        </p>
        <p>
          And then came the announcement: We won the Major League Hacking Best Use of Defang Award! 🏆
        </p>
        <p>
          For some of us, this was our first-ever hackathon submission. To say we were thrilled would be an understatement. We were over the moon!
        </p>

        <h3><strong>Lessons Beyond Coding</strong></h3>
        <p>
          This experience was more than just about building an app. It taught us invaluable lessons in:
        </p>
        <ul>
          <li><strong>Perseverance:</strong> Pushing through when the going got tough.</li>
          <li><strong>Teamwork:</strong> Leveraging each other's strengths and supporting one another.</li>
          <li><strong>Communication:</strong> Keeping everyone on the same page despite the chaos.</li>
          <li><strong>Creativity:</strong> Thinking outside the box to solve complex problems.</li>
        </ul>
        <p>
          Our success was a testament to what can be achieved when a group of passionate individuals come together with a shared vision.
        </p>

        <h3><strong>Celebrating in Style</strong></h3>
        <p>
          After the adrenaline rush subsided, we swapped our keyboards for bowling shoes and hit the lanes to celebrate. Strikes, spares, or gutter balls—it didn't matter. What mattered was unwinding together after an intense and rewarding journey.
        </p>

        <h3><strong>A Heartfelt Thank You</strong></h3>
        <p>
          A massive shoutout to the VTHacks organizers, mentors, and fellow participants who made this event unforgettable. To my incredible teammates—Ruba, Ram, and Sanjeev—you guys are the dream team. Here's to many more adventures and late-night coding sessions!
        </p>

        <h3><strong>Check Out AmiGo</strong></h3>
        <p>
          Curious about what we've built? We'd love for you to explore AmiGo:
        </p>
        <p><strong>Devpost Project Link:</strong> <a href="https://devpost.com/software/712142" target="_blank" rel="noopener noreferrer">AmiGo on Devpost</a></p>

        <h3><strong>What's Next?</strong></h3>
        <p>
          While the hackathon might be over, our journey with AmiGo is just beginning. We're excited to refine our app, add new features, and see how we can make ride-sharing a more connected and enjoyable experience.
        </p>
        <p>
          Stay tuned, and who knows? Maybe the next time you need a ride, AmiGo will be there to match you with your next great conversation.
        </p>
        <p>
          Until then, happy coding and safe travels!
        </p>

        {/* Book Carousel */}
        <div className="book-carousel">
          <div className="carousel-track">
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cloud2Popup;
