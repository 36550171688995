import React, { useState, useEffect } from "react";
import { FaUser, FaNewspaper, FaBriefcase, FaCode, FaLinkedin, FaGithub } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import styled from "@emotion/styled";

const SocialContainer = styled.div`
  position: fixed;
  top: 48%;
  left: 1.5rem;
  transform: translateY(-50%);

  ul {
    display: block;
  }

  .item + .item {
    margin-top: 1rem;
  }

  a {
    font-size: 2.3rem;
    color: rgb(119, 119, 121);
    &:hover {
      color: rgb(57, 134, 250);
    }
  }

  @media screen and (max-width: 1000px) {
    margin-top: 2rem;
    position: relative;
    top: 0;
    left: 0;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
    }

    a {
      font-size: 2.5rem;
      color: #151418;
      &:hover {
        color: rgb(57, 134, 250);
      }
    }

    .item + .item {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
`;

function FixSocialIcon() {
  const [activeSection, setActiveSection] = useState(""); // State to track active section
  const [isHome, setIsHome] = useState(true); // State to track if we're on the homepage

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsHome(true); // User is at the home page (top of the page)
      } else {
        setIsHome(false); // User has scrolled away from the home page
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <SocialContainer>
      <ul>
        {/* Only show section icons if not on the home page */}
        {!isHome && (
          <>
            {activeSection !== "about" && (
              <li className="item">
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={0}
                  onSetActive={() => setActiveSection("about")}
                  spy={true}
                >
                  <FaUser />
                </ScrollLink>
              </li>
            )}

            {activeSection !== "blog" && (
              <li className="item">
                <ScrollLink
                  to="blog"
                  smooth={true}
                  duration={0}
                  onSetActive={() => setActiveSection("blog")}
                  spy={true}
                >
                  <FaNewspaper />
                </ScrollLink>
              </li>
            )}

            {activeSection !== "timeline" && (
              <li className="item">
                <ScrollLink
                  to="timeline"
                  smooth={true}
                  duration={0}
                  onSetActive={() => setActiveSection("timeline")}
                  spy={true}
                >
                  <FaBriefcase />
                </ScrollLink>
              </li>
            )}

            {activeSection !== "projects" && (
              <li className="item">
                <ScrollLink
                  to="projects"
                  smooth={true}
                  duration={0}
                  onSetActive={() => setActiveSection("projects")}
                  spy={true}
                >
                  <FaCode />
                </ScrollLink>
              </li>
            )}
          </>
        )}

        {/* LinkedIn and GitHub should always be visible */}
        <li className="item">
          <a
            href="https://www.linkedin.com/in/sanjeevp26/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </li>
        <li className="item">
          <a
            href="https://github.com/Sanjeev2601"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
        </li>
      </ul>
    </SocialContainer>
  );
}

export default FixSocialIcon;
