import React, { useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import './VerticalTimeline.css';
import cloudImage from "../../Pics/ADP.png";

export default function Timeline() {
  const [activeSection, setActiveSection] = useState('work'); // 'work' or 'education'

  // New Work Experience section without vertical timeline
  const renderWorkExperience = () => (
    <div className="work-experience-section">
      <div className="experience-header">
        <h3 className="experience-title">ADP, Senior Member Technical</h3>
        <h4 className="experience-subtitle">July 2021 - June 2023</h4>
        <p className="experience-description">
          Spearheaded frontend development and optimization for ADP's WFN product, boosting user interaction by 30%, while developing components to automate performance dashboard scenarios, reducing manual QA by 90% and accelerating project delivery by 20%.
        </p>
      </div>
      <div className="experience-content">
        <div className="left-content">
          <div className="experience-box">
            <h4 className="experience-heading">Frontend Experience</h4>
            <ul className="experience-points">
              <li>Spearheaded the development and deployment of three major features for ADP’s WFN product using React, optimizing page architecture to boost user interaction by 30% and increase user satisfaction by 18%.</li>
              <li>Engineered reusable React components and general class libraries, improving development speed and front-end code quality by 25%.</li>
              <li>Enhanced cross-browser compatibility and responsive design, ensuring consistent user experience across platforms.</li>
              <li>Improved UI performance through optimization techniques, reducing page load times and improving user engagement metrics.</li>
              <li>Collaborated with design teams to implement an intuitive and accessible UI, improving overall user satisfaction scores by 18%.</li>
            </ul>
          </div>
          <div className="experience-box">
            <h4 className="experience-heading">Backend Experience</h4>
            <ul className="experience-points">
              <li>Integrated secure backend API calls into the frontend, ensuring smooth data transactions for ADP’s workforce management product.</li>
              <li>Optimized database queries and API responses to improve backend performance, resulting in faster data retrieval and reduced server load.</li>
              <li>Designed and integrated RESTful APIs for seamless communication between frontend and backend services.</li>
            </ul>
          </div>
        </div>
        <div className="right-content">
          <img src={cloudImage} alt="ADP Cloud Logo" className="cloud-image" />
          <div className="experience-box">
            <h4 className="experience-heading">System & Software Testing</h4>
            <ul className="experience-points">
              <li>Automated performance dashboard scenarios using Selenium and Jenkins, reducing manual QA efforts by 90% and improving testing efficiency.</li>
              <li>Created end-to-end test cases and automated scripts, ensuring seamless integration and functionality of newly deployed features.</li>
              <li>Led efforts to document workflows on Confluence, promoting scalability and knowledge sharing within the development team.</li>
              <li>Optimized the CI/CD pipeline, resulting in faster deployment cycles and fewer bugs in production.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  // Education section with hourglass end icon
  const renderEducation = () => (
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={{ background: '#1e1e1e', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid rgb(33, 150, 243)' }}
        date="August 2023 - Present"
        dateClassName="custom-date-color"
        iconStyle={{ background: '#ffd966', color: '#fff' }}
        icon={<FontAwesomeIcon icon={faGraduationCap} />}
      >
        <h3 className="vertical-timeline-element-title">MEng Computer Science & Applications</h3>
        <h4 className="vertical-timeline-element-subtitle">Virginia Tech</h4>
        <h4 className="vertical-timeline-element-subtitle">Falls Church, VA</h4>
        <p className="vertical-timeline-element-desc">
          Relevant Coursework: Data Structures and Algorithms, Operating Systems, Web Application Development, 
          Cloud Computing, Intro to AI, Software Engineering, Fundamentals of Info Security,
          Ethics & Professionalism.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={{ background: '#1e1e1e', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid rgb(33, 150, 243)' }}
        date="August 2017 - May 2021"
        dateClassName="custom-date-color"
        iconStyle={{ background: '#ffd966', color: '#fff' }}
        icon={<FontAwesomeIcon icon={faGraduationCap} />}
      >
        <h3 className="vertical-timeline-element-title">B.Tech Electrical & Electronics Engineering</h3>
        <h4 className="vertical-timeline-element-subtitle">NIT Warangal</h4>
        <h4 className="vertical-timeline-element-subtitle">Telangana, India</h4>
        <p className="vertical-timeline-element-desc">
          Relevant Coursework: Problem Solving and Computer Programming, Data Structures, Probability & Statistics.
        </p>
      </VerticalTimelineElement>

      {/* Adding the hourglass icon to signify the end */}
      <VerticalTimelineElement
        iconStyle={{ background: '#ffd966', color: '#fff' }}
        icon={<FontAwesomeIcon icon={faHourglassEnd} />}
      />
    </VerticalTimeline>
  );

  return (
    <section id="timeline">
      <div className="timeline-container">
        <div className="section-switcher">
          <button
            className={`switch-button ${activeSection === 'work' ? 'active' : ''}`}
            onClick={() => setActiveSection('work')}
          >
            Work Experience
          </button>
          <button
            className={`switch-button ${activeSection === 'education' ? 'active' : ''}`}
            onClick={() => setActiveSection('education')}
          >
            Education
          </button>
        </div>
        {activeSection === 'work' ? renderWorkExperience() : renderEducation()}
      </div>
    </section>
  );
}
