// --------------------------------------- Project List
import translectImage from "../Pics/Translect.png";
import goaldenImage from "../Pics/Goalden.png";
import amigoImage from "../Pics/Amigo.png";

export const ProjectList = [
  {
    img: translectImage, 
    title: "Translect.ai",
    description:
      "A speech-to-speech translator with real-time lip-sync, making education accessible across languages. It enhances understanding by synchronizing translated speech with the speaker's lips.",
    tech_stack: ["React", "Express.js", "Typrescript", "Azure Blob Storage", "Azure Cosmos DB", "Wav2Lip", "ElevenLabs", "HTML", "CSS"],
    github_url: "https://github.com/Sanjeev2601/Global_Teach",
    demo_url: "https://devpost.com/software/replica",
  },
  {
    img: goaldenImage, 
    title: "Goalden - Productivity Tool",
    description:
      "A productivity platform with AI-generated subtasks, job tracking, and time management features. Built with Claude.ai, Langchain, and prompt engineering for enhanced AI capabilities.",
    tech_stack: ["React", "Claude.ai", "Langchain", "LLMs", "Prompt Engineering"],
    github_url: "https://github.com/rubavignesh/shellhacks-frontend",
    demo_url: "https://www.goalden.co/",
  },
  {
    img: amigoImage,
    title: "AmiGo",
    description:
      "A full-stack mobile app that matches students for carpooling based on user interests. It integrates Azure Custom Vision for facial recognition and a K-Means clustering recommendation system.",
    tech_stack: ["React Native", "Express.js","Azure Custom Vision", "K-Means", "Python", "Flask", "Defang", "MSSQL"],
    github_url: "https://github.com/ananyaprakashvt/match-service",
    demo_url: "https://devpost.com/software/amigo-tqnrvc",
  },
  {
    img: "/Libromart.png",
    title: "LibroMart",
    description:
      "Revolutionize your reading experience with my dynamic book store web application, offering an extensive catalog, seamless browsing, and secure transactions, all at your fingertips.",
    tech_stack: ["React Native", "HTML", "CSS", "Figma", "NodeJs"],
    github_url: "https://github.com/Sanjeev2601/LibroMart",
    demo_url: "https://youtu.be/lBd04AhEUr8",
  },
  {
    img: "/Pet.jpg",
    title: "PawsTime",
    description:
      "pawsTime aims to simplify and enhance pet care management through an integrated scheduling platform that adapts to users' changing needs because Because every pet deserves the VIP treatment!",
    tech_stack: ["React", "Firebase"],
    github_url: "https://github.com/rubavignesh/petpeaves",
    demo_url: "https://youtu.be/kts8_rC0Ibk",
  },
  {
    img: "/Casino.png",
    title: "Player's Den",
    description:
      "A website to register and play online casino games.",
    tech_stack: ["Angular", "SpringBoot", "HTML", "CSS", "MySQL"],
    github_url: "https://github.com/Sanjeev2601",
    demo_url: "https://www.youtube.com/watch?v=JxJXHuS87m0",
  },
  {
    img: "/mobile.jpg",
    title: "Mango",
    description:
      "A mobile outlook using C++",
    tech_stack: ["C++"],
    github_url: "https://github.com/Sanjeev2601/Mango",
    demo_url: "/",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg",
    name: "NextJs",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
    name: "Typescript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original.svg",
    name: "Docker",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
    name: "Firebase",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flask/flask-original.svg",
    name: "Flask",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/cplusplus/cplusplus-original.svg",
    name: "C++",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
    name: "Java",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/spring/spring-original.svg",
    name: "Spring Boot",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg",
    name: "Figma",
  },
  {
    img: "https://angular.io/assets/images/logos/angular/angular.svg",
    name: "Angular",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
];
