import React, { useEffect } from 'react';
import './Cloud1Popup.css';
import WelcomeImage from '../../Pics/Shell.jpg';
import B1Image from '../../Pics/Shell21.jpg';
import B2Image from '../../Pics/Shell22.jpg';
import B3Image from '../../Pics/Shell23.jpg';
import B4Image from '../../Pics/Shell24.jpg';
import B5Image from '../../Pics/Shell25.jpg';
import A1Image from '../../Pics/Shell1.jpg';
import A2Image from '../../Pics/Shell2.jpg';
import A3Image from '../../Pics/Shell3.jpg';
import A4Image from '../../Pics/Shell4.jpg';
import A5Image from '../../Pics/Shell5.jpg';
import A6Image from '../../Pics/Shell6.jpg';
import A7Image from '../../Pics/Shell7.jpg';
import C1Image from '../../Pics/Shell11.png';
import C2Image from '../../Pics/Shell12.png';
import C3Image from '../../Pics/Shell13.png';
import C4Image from '../../Pics/Shell14.png';
import C5Image from '../../Pics/Shell15.png';
import C6Image from '../../Pics/Shell16.png';

const Cloud3Popup = ({ closePopup }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-buttons">
          <button className="popup-button red" onClick={closePopup}></button>
          <button className="popup-button yellow" onClick={closePopup}></button>
          <button className="popup-button green" onClick={closePopup}></button>
        </div>

        <h2><strong>From Beaches to Bugs: Our Miami Adventure at ShellHacks 2024 🏖️🚀</strong></h2>
        <p><em>"Why fly to Miami for sun and sand when you can have 36 hours of coding madness?"</em></p>

        <div className='Welcome-Image-shell'>
            <img src={WelcomeImage} alt="Black Panther: Battle For Wakanda" />
        </div>

        <p>
          When two tech enthusiasts from Virginia decide to swap the rolling hills for the vibrant vibes of Miami, you know something epic is about to happen. Ruba and I packed our bags, charged our laptops, and hopped on a plane to the Magic City for ShellHacks 2024. Not only was it our first hackathon in Miami, but it was also our maiden voyage to this paradise. And boy, did Miami deliver!
        </p>

        <h3><strong>Miami: More Than Just Beaches</strong></h3>
        <p>
          Before we dove into lines of code and caffeine-fueled nights, we treated ourselves to the quintessential Miami experience.
        </p>
        <ul>
          <li><strong>Beach Bumming:</strong> We kicked off our adventure with toes in the sand, basking under the Florida sun. The ocean was as inviting as a freshly brewed cup of coffee at 2 AM during a hackathon.</li>
          <li><strong>Cruising Downtown:</strong> Driving through Miami's bustling downtown felt like navigating a live circuit board—bright lights, dynamic energy, and a rhythm that keeps you on your toes.</li>
          <div className="book-carousel-vertical">
            <div className="carousel-track-vertical">
                <img src={A1Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A2Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A3Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A4Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A5Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A6Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A7Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A1Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A2Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A3Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A4Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A5Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A6Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A7Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A1Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A2Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A3Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A4Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A5Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A6Image} alt="Black Panther: Battle For Wakanda" />
                <img src={A7Image} alt="Black Panther: Battle For Wakanda" />
            </div>
          </div>
          <li><strong>Wynwood Walls Wanderlust:</strong> As art enthusiasts (or at least pretending to be), we couldn't miss the Wynwood Walls. The graffiti and street art were as vibrant and diverse as the ideas bouncing around in our heads for the upcoming hackathon.</li>
          <li><strong>Evening Strolls:</strong> Walking through the streets of Miami at night was like stepping into a live music video—salsa beats, neon lights, and an atmosphere that screamed "Let's create something amazing!"</li>
        </ul>

        <h3><strong>Day 2: The Real Adventure Begins</strong></h3>
        <p>
          With sand still in our shoes and excitement in our veins, we headed early to Florida International University on Day 2. The campus was buzzing with fellow coders, all ready to turn caffeine into code.
        </p>

        <h3><strong>Networking Extravaganza</strong></h3>
        <p>
          Before the event officially kicked off, we mingled with other participants, mentors, and organizers. It was like speed dating but with tech talk—sharing ideas, forming alliances, and mentally preparing for the coding marathon ahead.
        </p>

        <h3><strong>From Sun to Screens: Diving into ShellHacks 2024</strong></h3>
        <p>Just when we thought Miami couldn't get any more exhilarating, ShellHacks turned up the heat.</p>

        <h3><strong>Introducing Goalden: Turning Productivity into Gold</strong></h3>
        <p>
          Amidst the tropical backdrop and the hum of excitement, Goalden was born—a comprehensive productivity tool designed to enhance time management and goal tracking. Inspired by the golden hues of Miami sunsets and our goal-driven mindset, Goalden became our golden ticket to a more organized life.
        </p>

        <h3><strong>Our Tech Treasure Map</strong></h3>
        <ul>
          <li><strong>Web Development:</strong> The trusty MERN Stack (MongoDB, Express, React, Node.js)—our bread and butter.</li>
          <li><strong>Authentication:</strong> Auth0 by Okta—because securing our app is cooler than cruising in a convertible down Ocean Drive.</li>
          <li><strong>AI Integration:</strong> Claude AI 3.5 by Anthropic—adding a sprinkle of artificial intelligence to make Goalden smarter than your average app.</li>
          <li><strong>Database Magic:</strong> MongoDB Atlas—keeping our data as organized as our code (or at least trying to).</li>
          <li><strong>Frontend Flair:</strong> Figma—designing interfaces as sleek as Miami's skyline.</li>
        </ul>

        <h3><strong>Deployment Destinations:</strong></h3>
        <ul>
          <li><strong>Frontend:</strong> Vercel—deploying faster than a Florida thunderstorm rolls in.</li>
          <li><strong>Backend:</strong> Render—because our server deserves a first-class ticket too.</li>
          <li><strong>Domain Swag:</strong> GoDaddy Registry—securing www.goalden.co to make our mark on the web.</li>
        </ul>

        <h3><strong>The Highs, The Lows, and The Bug That Got Away</strong></h3>
        <p>
          Hackathons are a rollercoaster ride. One moment you're celebrating a breakthrough; the next, you're questioning your life choices because of a bug that just won't budge. But it's all part of the adventure.
        </p>

        <h3><strong>Check Out Goalden</strong></h3>
        <p>
          We poured our hearts, souls, and probably a piece of our sanity into Goalden. We'd love for you to check it out:
        </p>
        <p><strong>Website:</strong> <a href="https://www.goalden.co" target="_blank" rel="noopener noreferrer">www.goalden.co</a></p>
        <p><strong>DevPost:</strong> <a href="https://devpost.com/software/goalden" target="_blank" rel="noopener noreferrer">Goalden on DevPost</a></p>

        <div className="book-carousel">
            <div className="carousel-track">
                <img src={C1Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C2Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C3Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C4Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C5Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C6Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C1Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C2Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C3Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C4Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C5Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C6Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C1Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C2Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C3Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C4Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C5Image} alt="Black Panther: Battle For Wakanda" />
                <img src={C6Image} alt="Black Panther: Battle For Wakanda" />
            </div>
          </div>
        <h3><strong>A Symphony of Thanks</strong></h3>
        <p>
          ShellHacks was more than an event; it was an experience woven with the threads of camaraderie, innovation, and a dash of Miami spice.
        </p>
        <p>
          To the Organizers: Robert J., Wei, Corey, Daniel, and the entire team—you orchestrated a masterpiece.
        </p>
        <p>To the Mentors: Your guidance was the wind in our sails.</p>
        <p>To Jonathan: Your insights were the hidden gems we needed.</p>
        <p>To Ruba: Couldn't have asked for a better partner in this wild ride.</p>

        <h3><strong>Lessons Under the Miami Sun</strong></h3>
        <ul>
          <li><strong>Embrace the Unknown:</strong> First time in Miami? First time integrating AI? Bring it on!</li>
          <li><strong>Community is Key:</strong> Networking isn't just about exchanging business cards; it's about sharing passions and dreams.</li>
          <li><strong>Balance is Vital:</strong> Sometimes stepping away from the screen to enjoy the sunset can spark the next big idea.</li>
        </ul>

        <h3><strong>The Journey Continues</strong></h3>
        <p>
          As we bid farewell to Miami's sandy shores and head back to Virginia's familiar landscapes, we're already plotting our next escapade. The magic of ShellHacks has ignited a flame that won't be dimmed anytime soon.
        </p>
        <p>Stay tuned for more stories of code, adventure, and perhaps a few more unexpected detours.</p>
        <p>Until next time, keep your code clean and your adventures messy!</p>
        <div className="book-carousel">
          <div className="carousel-track">
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cloud3Popup;
