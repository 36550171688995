import React, { useEffect } from 'react';
import './Cloud1Popup.css';
import WelcomeImage from '../../Pics/Patriot.jpg';
import B1Image from '../../Pics/Patriot21.png';
import B2Image from '../../Pics/Patriot22.png';
import B3Image from '../../Pics/Patriot23.png';
import B4Image from '../../Pics/Patriot24.png';
import B5Image from '../../Pics/Patriot25.png';
import B6Image from '../../Pics/Patriot26.png';
import A1Image from '../../Pics/Patriot1.jpg';
import A2Image from '../../Pics/Patriot2.jpg';
import A3Image from '../../Pics/patriot3.jpg';
import A4Image from '../../Pics/Patriot11.jpg';
import A5Image from '../../Pics/Patriot7.jpg';
import A6Image from '../../Pics/Patriot6.jpg';
import A7Image from '../../Pics/Patriot12.jpg';

const Cloud6Popup = ({ closePopup }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-buttons">
          <button className="popup-button red" onClick={closePopup}></button>
          <button className="popup-button yellow" onClick={closePopup}></button>
          <button className="popup-button green" onClick={closePopup}></button>
        </div>

        <h2><strong>When Reunions and FIEN fuel innovation: Our Epic Journey at PatriotHacks 2024 🚀</strong></h2>
        <p><em>"Who knew a hackathon could reunite old friends, create groundbreaking tech, and have the best food ever?"</em></p>

        <div className='Welcome-Image'>
            <img src={WelcomeImage} alt="Black Panther: Battle For Wakanda" />
        </div>

        <p>
          Just two weeks after our Miami escapade at ShellHacks, we found ourselves gearing up for another 36-hour coding marathon—this time at PatriotHacks 2024 hosted by George Mason University. The team? A dream squad comprising Ruba, Kunal, Nidhi, and yours truly. Little did we know that this hackathon would be more than just about code; it was about reunions, relentless vibing to our hype song, and, of course, creating something revolutionary.
        </p>

        <h3><strong>Hello Again, Old Friend!</strong></h3>
        <p>
          Before we dive into the tech extravaganza, let's talk about a serendipitous reunion that added a special sparkle to the weekend. I bumped into Tejas Ramesh, my high school buddy and former basketball teammate. We hadn't seen each other in seven years! Imagine the surprise and joy of reconnecting amidst the hustle of a hackathon. Between debugging sessions and brainstorming, we reminisced about our court antics, shared laughs, and caught up on life. It was pure bliss to blend nostalgia with innovation.
        </p>

        <h3><strong>Fueling Up: GMU's Culinary Delights</strong></h3>
        <p>
          Props to George Mason University for keeping our spirits (and stomachs) high with amazing food throughout the event. From gourmet sandwiches to midnight snacks that kept us coding into the wee hours, the culinary experience was nothing short of spectacular. Who says no to free food that's actually delicious?
        </p>

        <h3><strong>The Beat That Kept Us Going: FIEN</strong></h3>
        <p>
          Every epic journey needs a soundtrack, and ours was powered by "FIEN"—our official hype song. Whether we were debugging a tricky piece of code or celebrating a breakthrough, FIEN was blasting in the background, keeping the energy levels sky-high. Picture this: four coders, headphones on, heads bobbing in unison, fingers flying across keyboards. We vibed through the entire 36 hours, and honestly, we couldn't have done it without that beat.
        </p>

        <h3><strong>Introducing Translect.ai: Breaking Language Barriers in Real-Time</strong></h3>
        <p>
          Amidst the reunions and rhythms, we channeled our collective genius into creating Translect.ai—a speech-to-speech translator with real-time lip-sync capabilities. Our mission? To make education accessible across languages by synchronizing translated speech with the speaker's lip movements. Imagine watching a lecture in a foreign language and understanding it as if it were in your native tongue, all while the speaker's lips move in perfect harmony with the translated audio. Magic, right?
        </p>

        <h3><strong>🌟 Inspiration</strong></h3>
        <p>
          The idea sparked from witnessing the struggles in multilingual educational settings. Language barriers can turn learning from an exciting journey into a daunting task. We wanted to bridge that gap, ensuring that no student feels left behind due to language differences.
        </p>

        <h3><strong>💡 What Translect.ai Does</strong></h3>
        <p>
          Translect.ai is not just a translator; it's an immersive experience. It captures the speaker's speech, translates it in real-time, and synchronizes the translated audio with the speaker's lip movements. This ensures that the viewers feel a seamless connection, enhancing understanding and retention.
        </p>

        <div className="book-carousel">
          <div className="carousel-track">
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B6Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B6Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={B6Image} alt="Black Panther: Battle For Wakanda" />
          </div>
        </div>
        
        <h3><strong>🛠️ How We Built It</strong></h3>
        <ul>
          <li><strong>ElevenLabs API:</strong> our go-to for accurate, real-time speech translation, making language barriers a thing of the past.</li>
          <li><strong>Azure OpenAI's Whisper:</strong> capturing every word in real-time, ensuring nothing gets lost in translation.</li>
          <li><strong>Wav2Lip:</strong> our secret sauce for matching translated speech to lip movements, creating a seamless, immersive experience.</li>
          <li><strong>Azure Cosmos DB:</strong> keeping our data safe and accessible, with the reliability of a vault.</li>
          <li><strong>React.js:</strong> delivering a smooth, responsive user experience as intuitive as it is powerful.</li>
          <li><strong>Figma:</strong> bringing our ideas to life with a sleek, user-friendly design.</li>
        </ul>

        <h3><strong>🎓 What We Learned</strong></h3>
        <ul>
          <li><strong>Team Synergy:</strong> Collaboration was key. Each member brought a unique skill set to the table, and we learned the true power of teamwork.</li>
          <li><strong>Tech Stack Mastery:</strong> Navigated through the complexities of combining APIs and technologies, enhancing our problem-solving skills.</li>
          <li><strong>Time Management:</strong> Ironically, building a time management tool taught us a lot about managing our own time under pressure.</li>
        </ul>

        <h3><strong>Hacking, Reunions, and High-Fives</strong></h3>
        <p>
          Balancing the intensity of the hackathon with catching up with Tejas was a highlight. Between code sprints, we shared stories from our high school days, laughed about old basketball games, and marveled at how far we've come. It was the perfect blend of past and present, fueling our motivation to push through the 36 hours.
        </p>

        <h3><strong>Shoutouts and Gratitude</strong></h3>
        <p>
          To My Teammates: Ruba, Kunal, and Nidhi—your brilliance and dedication made Translect.ai possible. Here's to us and many more innovations!
        </p>
        <p>
          To George Mason University: For not just hosting a fantastic hackathon but for feeding us like royalty.
        </p>
        <p>
          To Tejas: Reconnecting with you was the cherry on top of an already amazing weekend.
        </p>
        <p>
          To FIEN: For being the backdrop of our hackathon hustle. You kept the vibes immaculate.
        </p>

        <div className="book-carousel-vertical">
          <div className="carousel-track-vertical">
            <img src={A1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A6Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A7Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A1Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A2Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A3Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A4Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A5Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A6Image} alt="Black Panther: Battle For Wakanda" />
            <img src={A7Image} alt="Black Panther: Battle For Wakanda" />
          </div>
        </div>

        <h3><strong>Final Thoughts</strong></h3>
        <p>
          PatriotHacks 2024 was more than a hackathon; it was a melting pot of innovation, friendship, and unforgettable experiences. We walked away not just with a groundbreaking project but with memories that'll last a lifetime.
        </p>
        <p>
          As we pack up our laptops and bid farewell to the incredible weekend, one thing's for sure: the fusion of technology, teamwork, and a dash of nostalgia creates something truly magical.
        </p>
        <p>Until the next adventure, keep coding, keep vibing, and never stop breaking barriers—both in tech and in life!</p>
      </div>
    </div>
  );
};

export default Cloud6Popup;
